import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>Bestaat niet</h1>
      <p>Jammer, een doodlopend pad.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
